.root {
    display: flex;
}

.frame-padding {
    padding-top: 64px;
    padding-left: 200px;
}

.full-width {
    width: 100%;
}

.align-center {
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
}
