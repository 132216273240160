.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.form-container {
    margin-top: 7%;
    width: 400px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
}